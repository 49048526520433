import React, { useEffect } from 'react'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import { push_toast } from '../utils/common'
import { useMeQuery } from '../hooks'
import useDeviceEventsStore from '../redux/store/useDeviceEventsStore'

const UserHandler = ({ user }: { user: any }) => {
  const channel_name = `user___${user.id}`
  const channel = useChannel(channel_name)

  useEvent<any>(channel, 'message', (arr) => {
    if (arr?.event_name === 'send_toast') {
      if (user?.role === 3) {
        return
      }

      push_toast(arr.message, arr?.type)

      return
    }
  })

  useEffect(() => {
    return () => {
      if (channel) {
        channel.disconnect()
        console.log('Disconnected from User Channel', channel_name)
      }
    }
  }, [])

  return (<></>)
}

const GaterHandler = ({ user }: { user: any }) => {
  // @ts-ignore
  const { events, addEvent, fetchInitialData } = useDeviceEventsStore()

  console.log('eventsevents', events)

  const channel_name = `gater___${user?.team?.schema_name}`
  const channel = useChannel(channel_name)

  useEvent<any>(channel, 'message', (arr) => {
    console.log('gater_event', arr)

    if (arr?.event_name === 'device_event') {
      let _dvc_event = JSON.parse(arr.message)

      console.log('device_event', _dvc_event)

      addEvent(_dvc_event)

      return
    }
  })

  useEffect(() => {
    if (events.length) return
    fetchInitialData()
  }, [])

  useEffect(() => {
    return () => {
      if (channel) {
        channel.disconnect()
        console.log('Disconnected from Gater Channel', channel_name)
      }
    }
  }, [])

  return (<></>)
}

export const UserWebsocketHandler = () => {
  const { data: me } = useMeQuery()

  if (me?.id) {
    return <UserHandler user={me}/>
  }

  return null
}

export const GaterWebsocketHandler = () => {
  const { data: me } = useMeQuery()

  if (me?.id) {
    return <GaterHandler user={me}/>
  }

  return null
}