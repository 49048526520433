import { qs_local_server } from '../queries-local-server'
import { localPostApi } from '../utils/LocalApi'
import { show_modal } from '../utils/Formatters'
import { qs } from '../queries'
import swal from 'sweetalert2'

export class PersonDataModel {
  id: string
  obj_number: number
  name: string
  id_in_equipment: number | null

  constructor(data: any) {
    this.id = data.id
    this.obj_number = data.obj_number
    this.name = data.name
    this.id_in_equipment = data.id_in_equipment

    return
  }

  static objects = {
    all: async (id: string) => {},
    get: async (id: string) => {},
  }

  getEquipmentUserId = () => {
    if (this.id_in_equipment) {
      return this.id_in_equipment
    } else {
      return this.obj_number
    }
  }

  local_enrollFaceClient = async (cropped_base64, access_group) => {
    return qs_local_server.devices_manager.enrollFaceClient(
      this.getEquipmentUserId(),
      this.name,
      cropped_base64,
      access_group
    )
  }

  local_deleteFace = async () => {
    const payload = {
      user_id: this.getEquipmentUserId()
    }
    return localPostApi('devices-manager/delete_face', payload, 60 * 1000)
  }

  local_deleteAllData = async () => {
    let payload = {
      user_id: this.getEquipmentUserId()
    }
    return localPostApi('devices-manager/delete_all_data_user', payload, 60 * 1000)
  }

  local_bioEnrollClient = async (bio_blob, bio_template, bio_template_zk9, bio_index, access_group) => {
    let formData = new FormData()
    formData.append('bio', bio_blob)
    formData.append('template', bio_template)
    formData.append('template_zk9', bio_template_zk9)
    formData.append('user_id', String(this.getEquipmentUserId()))

    // Avoid string null "null"
    if (this.id_in_equipment) {
      formData.append('id_in_equipment', String(this.id_in_equipment))
    }

    formData.append('user_name', this.name)
    formData.append('bio_index', bio_index)
    formData.append('access_group', JSON.stringify(access_group))

    return localPostApi(
      'devices-manager/bio_enroll_client',
      formData,
      4000, {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      })
      .then(res => res)
      .catch(err => Promise.reject(err))
  }

  local_deleteBio = async (bio_index) => {
    const payload = {
      user_id: this.getEquipmentUserId(),
      bio_index: bio_index
    }
    return localPostApi('devices-manager/delete_bio', payload, 60 * 1000)
  }

  local_cardEnrollClient = async (code, facility_code, access_group) => {
    let payload = {
      user_id: this.getEquipmentUserId(),
      user_name: this.name,

      code: code,
      facility_code: facility_code,

      access_group: access_group,
      is_guest: false
    }

    return localPostApi('devices-manager/card_enroll_client', payload, 60 * 1000)
  }

  local_deleteCard = async (code) => {
    const payload = {
      user_id: this.getEquipmentUserId(),
      person_id: this.getEquipmentUserId(),
      code: code
    }
    return localPostApi('devices-manager/delete_card', payload, 60 * 1000)
  }

  local_createDriverVehicle = async (
    code: string,
    access_driver_type: number,
    access_driver_id: number | string | null,
    access_devices_ids: number[] | string[],
    // para compatibilidade
    access_group_id: number | string,
    access_group: any,
    // remote only
    alias: any,
    vehicle_id: string | number | null,
    parking_id: string | number | null,
    unit_id: string | number | null,
    enable_ac_cloud_server: boolean | null,
  ) => {

    try {
      if (!code) show_modal(`Defina o código`, 'error')
      if (!access_driver_type) show_modal(`Defina o tipo`, 'error')
      if (!access_group_id) show_modal(`Defina a rota de acesso`, 'error')
      if (!access_devices_ids) show_modal(`Nenhum equipamento com permissão na Rota de Acesso`, 'error')

      code = String(code).toUpperCase()

      const remote_payload = {
        type: access_driver_type,
        status: 1,
        code: code, // Decimal
        person: this.id,
        sync_devices: enable_ac_cloud_server
      }

      // Evitar de remover, se usar a função de resync
      if (alias) remote_payload['alias'] = alias
      if (vehicle_id) remote_payload['vehicle'] = vehicle_id
      if (parking_id) remote_payload['parking'] = parking_id
      if (unit_id) remote_payload['unit'] = unit_id

      const ac_payload = {
        // pessoa
        user_id: this.getEquipmentUserId(),
        user_name: this.name,
        // acionador
        code: code,
        access_driver_type: access_driver_type,
        access_driver_id: access_driver_id,
        access_devices_ids: access_devices_ids,
        // Para compatibilidade
        access_group_id: access_group_id,
        access_group: access_group,
      }

      let check = await qs.access_drivers.filterByCode(code)
      console.log(check)

      if (check && check.length > 0) {
        if (check[0].person_id && check[0].person_id !== this.id) {
          show_modal(`Acionador já cadastrado, exclua ele primeiro. Id do usuário: ${check[0].person_id} `, 'error')

          return
        }

        await qs.access_drivers.patch(remote_payload, check[0]['id'])
          .catch((err) => {
            show_modal('Erro ao atualizar no servidor remoto', 'error')
            // Sobe o throw para evitar o código de dar continuidade
            throw err
          })

        if (!enable_ac_cloud_server) {
          await localPostApi('devices-manager/create_driver', ac_payload, 60 * 1000)
            .catch((err) => {
              show_modal('Verifique os erros para que seja possível cadastrar o acionador no servidor!', 'error')
              throw err
            })
        }

      } else {

        await qs.access_drivers.post(remote_payload)
          .catch((err) => {
            show_modal('Erro ao criar no servidor remoto', 'error')
            throw err
          })

        if (!enable_ac_cloud_server) {
          await localPostApi('devices-manager/create_driver', ac_payload, 60 * 1000)
            .catch((err) => {
              show_modal('Verifique os erros para que seja possível cadastrar o acionador no servidor!', 'error')
              throw err
            })
        }
      }

    } catch (e) {
      console.error(e)
      throw e
    }
  }

  local_deleteDriverVehicle = async (
    code: string,
    access_driver_type: number,
    access_driver_id: number | string | null,
    access_devices_ids: number[] | string[],
    access_group_id: number | string,
    // Compatibilidade
    access_group: any,
    enable_ac_cloud_server: boolean,
  ) => {

    try {
      if (code !== '' && code !== null && code !== undefined) {
        const swal_result = await swal({
          title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
          text: 'Essa ação não poderá ser desfeita',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não'
        })

        if (swal_result.value === true) {
          let ac_payload = {
            user_id: this.getEquipmentUserId(),
            code: code,

            access_driver_type: access_driver_type,
            access_driver_id: access_driver_id,
            access_devices_ids: access_devices_ids,

            access_group_id: access_group_id,
            access_group: access_group,
          }

          let remote_payload = {
            id: access_driver_id,
            person_id: this.id,
            type: access_driver_type,
            sync_devices: enable_ac_cloud_server
          }

          if (!enable_ac_cloud_server) {
            await localPostApi('devices-manager/delete_driver', ac_payload, 60 * 1000)
          }

          await qs.access_drivers.delete(remote_payload)
        }

      } else {
        show_modal('Erro no código do acionador de acesso', 'error')
      }

    } catch (e) {
      console.error(e)
      throw e
    }
  }

}
