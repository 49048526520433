import {
  Announcement,
  Build,
  CardTravel,
  CompareArrows,
  DirectionsCar,
  Dock,
  Event,
  Flag,
  FlashOn,
  Home,
  LocalMall,
  LocationSearching,
  Lock,
  Mail,
  Nfc,
  Notifications,
  OndemandVideo,
  People,
  PermDeviceInformation,
  PermScanWifi,
  QueuePlayNext,
  Search
} from 'material-ui-icons'
import Drawer from 'material-ui/Drawer'
import MenuItem from 'material-ui/MenuItem'
import { white } from 'material-ui/styles/colors'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { LARGE, SMALL } from 'material-ui/utils/withWidth'
import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import '../assets/css/App.css'
import { hasPerm } from '../components/acl'
import Header from '../components/Header'
import enviroment from '../enviroment'
import ThemeDefault from '../theme-default'
import { push_toast } from '../utils/common'
import { AccessManagerRequirements, LinearRequirements } from '../utils/HardwareService'
import AccessDocsListPage from './AccessDocs/AccessDocsListPage'
import BookListPage from './Books/components/BookListPage'
import ConstructionListPage from './Constructions/ConstructionListPage'
import DriversListPage from './Drivers/DriversListPage'
import EventListPage from './Events/EventListPage'
import VisitListPage from './Events/VisitListPage'
import AccessControlPage from './Guests/AccessControlPage'
import LivePage from './Live/LivePage'
import VideosPage from './Live/VideosPage'
import LockersListPage from './Lockers/LockersListPage'
import LostFormPage from './Losts/LostFormPage'
import LostListPage from './Losts/LostListPage'
import MailingFormPage from './Mailings/MailingFormPage'
import MailingListPage from './Mailings/MailingListPage'
import MailingPouchPage from './Mailings/MailingPouchPage'
import MovingListPage from './Movings/MovingListPage'
import NoticeListPage from './Notices/components/NoticeListPage'
import ObjectFormPage from './Objects/ObjectFormPage'
import ObjectListPage from './Objects/ObjectListPage'
import PanelPage from './PanelPage'
import ParkingsListPage from './Parkings/ParkingsListPage'
import { person_actions } from './People'
import PeopleListModalPage from './People/components/PeopleListModalPage'
import { SpreadsheetMenu } from './Spreadsheets/components/SpreadsheetMenu'
import TicketListPage from './Tickets/components/TicketListPage'
import VehiclesListPage from './Vehicles/VehiclesListPage'
import WorkShiftFormPage from './WorkShifts/WorkShiftFormPage'
import WorkShiftListPage from './WorkShifts/WorkShiftListPage'
import { PusherProvider } from '@harelpls/use-pusher'
import { actionMap as system_actions } from '../redux/modules/system'
import AccessLogFormPage from './Guests/AccessLogFormPage'
import AccessLogFastFormPage from './Guests/AccessLogFastFormPage'
import { Icon } from 'semantic-ui-react'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navDrawerOpen: true
    }
  }

  componentDidMount() {
    const token = localStorage.getItem('token')
    const current_user = localStorage.getItem('current_user')

    if (token === null || token === '' || token === undefined || current_user === null || current_user === '' || current_user === undefined) {
      push_toast('Entre novamente!', 'error')
      this.props.history.push('/login')
    }

    LinearRequirements()
    AccessManagerRequirements()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.width !== nextProps.width) {
      this.setState({ navDrawerOpen: nextProps.width === LARGE })
    }
  }

  handleChangeRequestNavDrawer() {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen
    })
  }

  render() {
    const token = localStorage.getItem('token')
    const current_user = localStorage.getItem('current_user')

    if (!token || !current_user) {
      return (
        <div></div>
      )
    }

    let { navDrawerOpen } = this.state
    const paddingLeftDrawerOpen = 220

    const styles = {
      container: {
        margin: '80px 10px 20px 0px',
        backgroundColor: '#EEF1F5',
        paddingLeft: navDrawerOpen && this.props.width !== SMALL ? paddingLeftDrawerOpen : 0
      }
    }

    const LeftDrawer = (props) => {
      let { navDrawerOpen } = props

      const styles = {
        logo: {
          cursor: 'pointer',
          backgroundColor: '#032f58',
          height: 56
        },
        menuItem: {
          color: white,
          fontSize: 13,
          padding: '0px'
        },
        avatar: {
          div: {
            padding: '15px 0 20px 15px',
            height: 45
          },
          icon: {
            float: 'left',
            display: 'block',
            marginRight: 15,
            boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)'
          },
          span: {
            paddingTop: 12,
            display: 'block',
            color: 'white',
            fontWeight: 300,
            textShadow: '1px 1px #444'
          }
        }
      }

      const data = {
        menus: [
          { text: 'Vídeos de Ajuda', icon: <OndemandVideo color="#FFFFFF"/>, link: '/admin/videos' },

          { text: 'Monitoramento', icon: <Search color="#FFFFFF"/>, link: '/admin/live' },

          hasPerm(['access_control.view_accesslog']) ? {
            text: 'Registrar Entrada',
            icon: <QueuePlayNext color="#FFFFFF"/>,
            action: () => {
              this.props.history.replace(this.props.history.location.pathname, {})
              this.props.showAccessLogForm(true)
            }
          } : false,

          hasPerm(['access_control.view_accesslog']) ? {
            text: 'Registro Rápido',
            icon: <FlashOn color="#FFFFFF"/>,
            action: () => {
              this.props.showAccessLogFastForm(true)
            }
          } : false,

          hasPerm(['access_control.view_accesslog']) ? { text: 'Registro de Acesso', icon: <People color="#FFFFFF"/>, link: '/admin/access-logs' } : false,
          hasPerm(['unit.view_person']) ? { text: 'Pessoas', icon: <Home color="#FFFFFF"/>, action: () => this.props.modalPersonList(true) } : false,
          // hasPerm(['guest.view_guest']) ? {text: 'Visitantes', icon: <People color'#FFFFFF/>, link: '/admin/guests'} : false,
          hasPerm(['mailing.view_mailing']) ? {
            text: 'Correspondências',
            icon: <Mail color="#FFFFFF"/>,
            link: '/admin/mailings',
            shortcutAction: () => this.props.history.push('/admin/mailing-form'),
          } : false,
          hasPerm(['mailing.view_mailingpouch']) ? { text: 'Malotes', icon: <Mail color="#FFFFFF"/>, link: '/admin/mailing-pouchs' } : false,
          hasPerm(['maintenance.view_workshift']) ? {
            text: 'Passagem de Turnos',
            icon: <CompareArrows color="#FFFFFF"/>,
            link: '/admin/work-shifts'
          } : false,
          hasPerm(['object_control.view_objectcontrol']) ? {
            text: 'Objetos',
            icon: <LocalMall color="#FFFFFF"/>,
            link: '/admin/objects',
            shortcutAction: () => this.props.history.push('/admin/object-form'),
          } : false,
          hasPerm(['unit.view_lost']) ? {
            text: 'Achados e Perdidos',
            icon: <LocationSearching color="#FFFFFF"/>,
            link: '/admin/losts',
            shortcutAction: () => this.props.history.push('/admin/lost-form'),
          } : false,
          hasPerm(['access_control.view_accesslog']) ? { text: 'Eventos', icon: <Notifications color="#FFFFFF"/>, link: '/admin/events' } : false
        ]
      }

      const data2 = {
        menus: [
          hasPerm(['notice.view_notice']) ? { text: 'Comunicados', icon: <Announcement color="#FFFFFF"/>, link: '/admin/notices' } : false,
          hasPerm(['ticket.view_ticket']) ? { text: 'Ocorrências', icon: <Flag color="#FFFFFF"/>, link: '/admin/tickets' } : false,
          hasPerm(['book.view_book']) ? { text: 'Reservas', icon: <Event color="#FFFFFF"/>, link: '/admin/books' } : false,
          hasPerm(['moving.view_moving']) ? { text: 'Mudanças', icon: <CardTravel color="#FFFFFF"/>, link: '/admin/movings' } : false,
          hasPerm(['unit.view_vehicle']) ? { text: 'Veículos', icon: <DirectionsCar color="#FFFFFF"/>, link: '/admin/vehicles' } : false,
          hasPerm(['unit.view_parking']) ? { text: 'Vagas', icon: <Nfc color="#FFFFFF"/>, link: '/admin/parkings' } : false,
          hasPerm(['unit.view_locker']) ? { text: 'Escaninhos', icon: <Lock color="#FFFFFF"/>, link: '/admin/lockers' } : false,
          hasPerm(['construction.view_construction']) ? { text: 'Obras', icon: <Build color="#FFFFFF"/>, link: '/admin/constructions' } : false,
          hasPerm(['access_control.view_accessdriver'])
            ? { text: 'Acionadores', icon: <PermDeviceInformation color="#FFFFFF"/>, link: '/admin/drivers' }
            : false,
          hasPerm(['access_control.view_accesslog']) ? { text: 'Visitas Ativas', icon: <PermScanWifi color="#FFFFFF"/>, link: '/admin/visits' } : false,
          hasPerm(['access_control.view_accessdoc']) ? { text: 'Termos de Autorização', icon: <Dock color="#FFFFFF"/>, link: '/admin/access-docs' } : false
        ]
      }

      return (
        <Drawer
          docked={true}
          open={navDrawerOpen}>

          <div style={styles.logo}>
            <img src={require('../assets/images/logo-drawer.png')} width={180}/>
          </div>
          <div>

            <div style={{ background: '#051c2e' }}>
              {data.menus.filter(i => i !== false).filter(i => !!i).map((menu, index) =>
                menu.action ?
                  <MenuItem
                    key={index}
                    className={'menu-items'}
                    style={['Registrar Entrada', 'Registro Rápido'].includes(menu.text) ? { ...styles.menuItem, backgroundColor: '#21ba45' } : styles.menuItem}
                    primaryText={menu.text}
                    leftIcon={menu.icon}
                    onClick={menu.action}
                  /> :
                  <MenuItem
                    key={index}
                    className={'menu-items'}
                    style={styles.menuItem}
                    primaryText={<>
                      {menu.text}
                      {menu.shortcutAction &&
                        <div style={{ float: 'right' }}><Icon color="green" size="large" name="plus" onClick={() => {
                          setTimeout(() => {
                            menu.shortcutAction()
                          }, 800)
                        }}/>
                        </div>}
                    </>}
                    leftIcon={menu.icon}
                    onClick={() => {
                      this.props.history.push(menu.link)
                    }}
                  />
              )}
            </div>

            <SpreadsheetMenu/>

            <div>
              {data2.menus.filter(i => i !== false).filter(i => !!i).map((menu, index) =>
                menu.action ?
                  <MenuItem
                    key={index}
                    className={'menu-items'}
                    style={styles.menuItem}
                    primaryText={menu.text}
                    leftIcon={menu.icon}
                    onClick={menu.action}
                  /> :
                  <MenuItem
                    key={index}
                    className={'menu-items'}
                    style={styles.menuItem}
                    primaryText={menu.text}
                    leftIcon={menu.icon}
                    onClick={() => {
                      this.props.history.push(menu.link)
                    }}
                  />
              )}
            </div>

            <br/>
            <em style={{ color: '#5e88a9', paddingLeft: '20px', fontSize: '12px' }}>v{enviroment().APP_VERSION_READABLE}/0503-A</em>
            <br/>
          </div>
        </Drawer>
      )
    }

    return (
      <MuiThemeProvider muiTheme={ThemeDefault}>
        <PusherProvider
          wsHost={'wsrt.condoid.com.br'}
          wsPort={443}
          wssPort={443}
          forceTLS={false}
          disableStats={true}
          enabledTransports={['ws', 'wss']}
          clientKey={'app-key'}
          cluster={'nop'}
        >

          <div style={{ backgroundColor: '#EEF1F5' }}>

            <Header/>

            <LeftDrawer navDrawerOpen={navDrawerOpen} username="Admin"/>

            <div style={styles.container}>
              <Route path="/admin/live" component={LivePage}/>
              <Route path="/admin/videos" component={VideosPage}/>
              <Route path="/admin/access-logs" component={AccessControlPage}/>
              {/*<Route path="/admin/access-log-form" component={AccessLogFormPage}/>*/}
              <Route path="/admin/drivers" component={DriversListPage}/>
              <Route path="/admin/notices" component={NoticeListPage}/>
              <Route path="/admin/tickets" component={TicketListPage}/>
              <Route path="/admin/books" component={BookListPage}/>
              <Route path="/admin/movings" component={MovingListPage}/>
              <Route path="/admin/constructions" component={ConstructionListPage}/>
              <Route path="/admin/vehicles" component={VehiclesListPage}/>
              <Route path="/admin/parkings" component={ParkingsListPage}/>
              <Route path="/admin/lockers" component={LockersListPage}/>
              <Route path="/admin/objects" component={ObjectListPage}/>
              <Route path="/admin/object-form" component={ObjectFormPage}/>
              <Route path="/admin/mailings" component={MailingListPage}/>
              <Route path="/admin/mailing-form" component={MailingFormPage}/>
              <Route path="/admin/mailing-pouchs" component={MailingPouchPage}/>
              <Route path="/admin/losts" component={LostListPage}/>
              <Route path="/admin/lost-form" component={LostFormPage}/>
              <Route path="/admin/events" component={EventListPage}/>
              <Route path="/admin/visits" component={VisitListPage}/>
              <Route path="/admin/panel-hdw" component={PanelPage}/>
              <Route path="/admin/work-shifts" component={WorkShiftListPage}/>
              <Route path="/admin/work-shift-form" component={WorkShiftFormPage}/>
              <Route path="/admin/access-docs" component={AccessDocsListPage}/>
            </div>

            {this.props.showModalPeopleList && <PeopleListModalPage/>}
            {this.props.show_access_log_form && <AccessLogFormPage/>}
            {this.props.show_access_log_fast_form && <AccessLogFastFormPage/>}
          </div>

        </PusherProvider>
      </MuiThemeProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    showModalPeopleList: state.people.showModalList,
    show_access_log_form: state.system.show_access_log_form,
    show_access_log_fast_form: state.system.show_access_log_fast_form,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    modalPersonList: (data) => dispatch(person_actions.modal_list(data)),
    showAccessLogForm: (data) => dispatch(system_actions.showAccessLogForm(data)),
    showAccessLogFastForm: (data) => dispatch(system_actions.showAccessLogFastForm(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
