import React from 'react'
import { Link } from 'react-router-dom'
import { Form } from 'semantic-ui-react'
import PageBase from '../../components/PageBase'
import { Formik } from 'formik'
import { postApi } from '../../utils/api'
import ActivityLoading from '../../components/Loading'
import { show_modal } from '../../utils/Formatters'
import UnitsSelect from '../../components/form/UnitsSelect'
import { WebcamCaptureUploader } from '../../components/WebcamCapture'
import ToasterService from '../../services/ToasterService'
import PeopleByUnitSelect from '../../components/form/PeopleByUnitSelect'

export default class MailingFormPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  render() {
    return (
      <PageBase title="Nova Correspondência" showFilter={false}>
        <div>
          <Formik
            ref={node => (this.form = node)}
            initialValues={{
              from_field: '',
              to: '',
              description: '',
              unit: '',
              status: 1,
              files_attach: [],
            }}
            validate={values => {
              let errors = {}

              if (!values.from_field) errors.from_field = 'Digite o nome do remetente'
              if (!values.to) errors.to = 'Digite o nome do destinatário'
              if (!values.status) errors.status = 'Selecione o status'
              return errors
            }}
            onSubmit={(values) => {
              this.setState({ loading: true })

              postApi('admin/mailings', values).then((res) => {
                this.setState({ loading: false })
                this.props.history.push('/admin/mailings')
                show_modal('Corrêspondencia salva!', 'success')
              }).catch((err) => {
                this.setState({ loading: false })
                ToasterService.sendErrors(err)
              })
            }}
            render={({ values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue }) => (
              <Form className="ui form large error">
                <div className={errors.from_field ? 'field error' : 'field'}>
                  <label>Remetente</label>
                  <input type="text"
                         name="from_field"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.from_field}/>

                  {touched.from_field && errors.from_field &&
                    <h5 className="ui header red">{errors.from_field}</h5>}
                </div>

                <div className="two fields">
                  <UnitsSelect name="unit" label={'Selecione a Unidade'}/>

                  <PeopleByUnitSelect
                    unit_id={values.unit}
                    name={'person__temp'}
                    label="Morador"
                    onChangePos={(a, b) => {
                      if (Array.isArray(b?.options)) {
                        let selected = b.options.find(i => i.key === b.value)
                        if (selected.text && selected.key) {
                          setFieldValue('to', selected.text)
                        }
                      }
                    }}
                  />
                </div>

                <div className={errors.to ? 'field error' : 'field'}>
                  <label>Destinatário</label>
                  <input type="text"
                         name="to"
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={values.to}/>

                  {touched.to && errors.to && <h5 className="ui header red">{errors.to}</h5>}
                </div>

                <div className="field">
                  <label>Descrição</label>
                  <textarea rows="2"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}/>
                  {touched.description && errors.description &&
                    <h5 className="ui header red">{errors.description}</h5>}
                </div>

                <br/>
                <br/>

                <WebcamCaptureUploader values={values} setFieldValue={setFieldValue} size="large"/>

                <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                <Link to="/admin/mailings">
                  <button className="ui red button right floated large">Cancelar</button>
                </Link>
              </Form>
            )}
          />
          <small>Ao salvar, será enviado uma notificação para o responsável da unidade.</small>
        </div>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}