import enviroment from '../enviroment'
import {apiAxios} from '../services/api'
import {AccessAuthorizationModel} from '../typings'
import {ListRequestResult} from './query_utils'
import ToasterService from '../services/ToasterService'

export const access_authorizations = {
  all_by_unit: async (unit_id: number) => {
    const { data } = await apiAxios.get<ListRequestResult<AccessAuthorizationModel[]>>(
      enviroment().apiUrl + `admin/access-authorizations/gater_list?unit_id=${unit_id}`)

    return data.data
  },
  access_control_enroll: async (item_id: any) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + `admin/access-authorizations/${item_id}/access_control_enroll`, {})
      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  access_control_delete: async (item_id: any) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + `admin/access-authorizations/${item_id}/access_control_delete`, {})
      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },

}