import React, { Component } from 'react'
import { FormikSelect } from './FormInputs'
import { Field } from 'formik'
import { person_actions } from '../../containers/People'
import { connect } from 'react-redux'

class PeopleByUnitSelect extends Component {
  static defaultProps = {
    unit_id: null
  }

  componentDidMount() {
    if (this.props.unit_id) this.props.getObjects(this.props.unit_id)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.unit_id !== this.props.unit_id) {
      this.props.resetObjects()

      if (this.props.unit_id) this.props.getObjects(this.props.unit_id)
    }
  }

  componentWillUnmount() {
    this.props.resetObjects()
  }

  render() {
    return (
      <Field
        label={this.props.label ? this.props.label : 'Morador'}
        name={this.props.name ? this.props.name : 'person'}
        component={FormikSelect}
        options={this.props.data}
        search={true}
        id_option='id'
        label_option='name'
        width={this.props.width ? this.props.width : null}
        onChange={this.props.onChange}
        onChangePos={this.props.onChangePos}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    data: state.people.by_unit,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getObjects: (id) => dispatch(person_actions.by_unit(id)),
    resetObjects: () => dispatch(person_actions.by_unit_success([])),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleByUnitSelect)