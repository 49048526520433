import create from 'zustand'
import { qs } from '../../queries'

const useDeviceEventsStore = create<{ events: any[] }>((set, get) => ({
  events: [],

  // Função para buscar o estado inicial via API
  fetchInitialData: async () => {

    console.log(1111111)

    // Verifica se o estado já foi carregado
    if (get().events.length) return

    console.log(2222222)
    try {
      console.log(33333)
      const response = await qs.access_devices.accessDeviceEvents()
      console.log('response', response)
      set({ events: response })
    } catch (error) {
      console.error('Erro ao buscar o estado inicial:', error)
    }
  },

  addEvent: (new_event) => set((state) => ({
    events: [...state.events, new_event]
  })),

  setState: (data: any) => {
    return set({
      events: data
    })
  }
}))

export default useDeviceEventsStore