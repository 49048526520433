import axios from 'axios'
import moment from 'moment'
import enviroment from '../enviroment'
import UpdaterService from '../services/UpdaterService'
import { LocalDB } from '../utils/LocalDB'
import { access_authorizations } from './access_authorizations'
import { access_buttons } from './access_buttons'
import { access_drivers } from './access_drivers'
import { access_groups } from './access_groups'
import { access_logs } from './access_logs'
import { access_reasons } from './access_reasons'
import { guests } from './guests'
import { people } from './people'
import { profile_photos } from './profile_photos'
import { spreadsheet_rows, spreadsheets } from './spreadsheets'
import { units } from './units'
import { user } from './user'
import { access_devices } from './access_devices'

const utils = {
  ping: async () => {
    const server_settings = LocalDB.getItem('settings')
    const server_version = server_settings?._server_version

    const current_user = LocalDB.getItem('current_user')
    const user_id = current_user?.id
    const schema_name = current_user?.team?.schema_name

    const wrapper_version = await UpdaterService.getWrapperVersionHandled()

    let payload = {
      platform: 'desktop_ui',
      app_version: enviroment().APP_VERSION_READABLE,
      wrapper_version: wrapper_version,
      user_id: user_id,
      schema_name: schema_name,
      server_version: server_version,
      server_settings: server_settings,
      now_moment: moment().toJSON(),
      now_moment2: moment().toISOString(),
      now_moment3: moment().format()
    }

    axios.post('https://services.condoid.com.br/access-control-ping', payload)
      .then(_res => {})
      .catch(reason => console.error(reason))
  }
}

export const qs = {
  user,
  units,
  guests,
  access_authorizations,
  spreadsheets,
  spreadsheet_rows,
  access_groups,
  access_reasons,
  access_drivers,
  access_buttons,
  profile_photos,
  people,
  access_logs,
  access_devices,
  utils
}
