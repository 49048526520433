import { connect as formikConnect, Field, FormikProps } from 'formik'
import React from 'react'
import { useQuery } from 'react-query'
import { qs } from '../../queries'
import { FormikSelect } from './FormInputs'

type Props = {
  label: string;
  name: string;
  unit_id: string;
  formik?: FormikProps<{ access_group: number | string }>
}

const ParkingByUnit = (props: Props) => {
  const { isLoading, data } = useQuery<any[]>(`getParkingByUnit_${props.unit_id}`, () => qs.units.parkings_by_unit(props.unit_id))

  return (
    <Field
      label={props.label ? props.label : 'Vaga'}
      name={props.name ? props.name : 'parking'}
      component={FormikSelect}
      options={data}
      search={true}
      id_option="id"
      label_option="with_pavement"
      // onChangePos={(evt, b) => {
      //   if (data) {
      //     for (let i of data) {
      //       if (i.id === b.value) {
      //         // @ts-ignore
      //         props.formik.setFieldValue('access_group', i)
      //       }
      //     }
      //   }
      // }}
    />
  )
}

export default formikConnect(ParkingByUnit)
