import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Divider, Dropdown, Grid, Header, Image, Message, Tab, Table } from 'semantic-ui-react'
import swal from 'sweetalert2'
import { hasPerm } from '../../../components/acl'
import BioCardManager from '../../../components/devices/BioCardManager'
import VehicleAccessManager from '../../../components/devices/VehicleAccessManager'
import FaceEnroll from '../../../components/devices/FaceEnroll'
import PwdManager from '../../../components/devices/PwdManager'
import GuaritaManager from '../../../components/GuaritaManager'
import ActivityLoading from '../../../components/Loading'
import NeokorosManager from '../../../components/NeokorosManager'
import ServerServicePopup from '../../../components/ServerServicePopup'
import { qs_local_server } from '../../../queries-local-server'
import ToasterService from '../../../services/ToasterService'
import { postApi } from '../../../utils/api'
import { giv, gv, hv } from '../../../utils/common'
import { getAccessGroups } from '../../../utils/DataService'
import { isPrivate } from '../../../utils/form'
import { localPostApi_ValidateLocalServer, requestToServerCacheUpdate } from '../../../utils/LocalApi'
import PrivacyData from '../../../utils/PrivacyData'
import { person_actions } from '../people'

const typesUnitPerson = [
  { value: 1, label: 'Proprietário' },
  { value: 2, label: 'Inquilino' }
]

const statusUnitPerson = [
  { value: 1, label: 'Ativo' },
  { value: 0, label: 'Inativo' }
]

class PeopleFormPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      parkings: [],
      access_groups: [],
      access_groups_raw: [],
      settings: null
    }
  }

  componentDidMount = () => {
    qs_local_server.settings.all().then(res => this.setState({ settings: res }))
    getAccessGroups().then((data) => {
      const access_groups = data.map((item) => {
        return { 'key': item.id, 'text': item.name, 'value': item.id }
      })

      this.setState({ access_groups: access_groups, access_groups_raw: data })
    }).catch((err) => {
      swal({ type: 'error', title: 'Não foi possível recuperar os perfis de acesso.' })
    })
    localPostApi_ValidateLocalServer('access-manager/sync_access_manager')
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  formatStatus = (value) => {
    for (let item of statusUnitPerson) {
      if (value === item.value) return item.label
    }
  }

  formatType = (value) => {
    for (let item of typesUnitPerson) {
      if (value === item.value) return item.label
    }
  }

  handle_group_change = (event, data) => {
    if (data.value !== this.props.current.access_group_id) {
      this.setState({ loading: true })

      postApi(`admin/people/${this.props.current.id}/change_access_group?is_generic=true`, { access_group_id: data.value, slave_access_group: false })
        .then((res) => {
          try {
            requestToServerCacheUpdate('people')
          } catch (e) { }

          this.setState({ loading: false })
          this.props.getObject(this.props.current.id)
          ToasterService.success('Rota de acesso alterada!')

        })
        .catch((err) => {
          this.setState({ loading: false })
          ToasterService.sendErrors(err)
        })

    }
  }

  handle_slave_group_change = (event, data) => {
    if (data.value !== this.props.current.slave_access_group_id) {
      this.setState({ loading: true })

      postApi(`admin/people/${this.props.current.id}/change_access_group?is_generic=true`, { access_group_id: data.value, slave_access_group: true })
        .then((res) => {
          this.setState({ loading: false })
          this.props.getObject(this.props.current.id)
          ToasterService.success('Rota de acesso alterada!')

        })
        .catch((err) => {
          this.setState({ loading: false })
          ToasterService.sendErrors(err)
        })

    }
  }

  person_actived = () => {
    let person_status = 0

    if (hv(this.props, 'current.units') && this.props.current.units.length > 0) {
      for (let u of this.props.current.units) {
        if (hv(u, 'pivot_status')) person_status += u.pivot_status
      }
    }

    return person_status > 0
  }

  get_access_group = () => {
    if (this.state.access_groups_raw && this.props.current.access_group_id) {
      for (let i of this.state.access_groups_raw) {
        if (i.id === this.props.current.access_group_id) {
          return i
        }
      }
    }
  }

  get_slave_access_group = () => {
    if (this.state.access_groups_raw && this.props.current.slave_access_group_id) {
      for (let i of this.state.access_groups_raw) {
        if (i.id === this.props.current.slave_access_group_id) {
          return i
        }
      }
    }
  }

  getAccessGroupMergedDeviceIds = () => {
    console.log('merged__get_access_group', this.get_access_group())
    let master_access_group = this.get_access_group()
    let slave_access_devices_ids = giv(this.get_slave_access_group(), 'access_devices_ids', [])

    if (master_access_group) {
      let all_access_devices_ids = [...master_access_group.access_devices_ids, ...slave_access_devices_ids]

      master_access_group.access_devices_ids = [...new Set(all_access_devices_ids)]
    }

    return master_access_group
  }

  render() {
    const enable_ac_cloud_server = this.props.access_control_settings?.enable_ac_cloud_server
    const has_facial = this.props.access_control_settings?.has_facial
    const has_vehicle_access = this.props.access_control_settings?.has_vehicle_access
    const has_guarita_ip = this.props.access_control_settings?.has_guarita_ip

    let current_access_group = this.get_access_group()
    let current_slave_access_group = this.get_slave_access_group()
    let current_access_group_merged_device_ids = this.getAccessGroupMergedDeviceIds()
    console.log('current_access_group', current_access_group)
    console.log('current_slave_access_group', current_slave_access_group)
    console.log('current_access_group_merged_device_ids', current_access_group_merged_device_ids)

    let enable_sync_face = gv(this.state.settings, 'zk__face') === '1' ||
      gv(this.state.settings, 'hikvision__face') === '1' ||
      gv(this.state.settings, 'dahua__status') === '1' ||
      gv(this.state.settings, 'controlid__face') === '1'

    const bio_card = {
      menuItem: 'Cadastro de Digital e Cartão/Chaveiro',
      render: () =>
        <Tab.Pane key="tab3" as={'div'}>
          {current_access_group &&
            <BioCardManager resident={this.props.current}
                            person_status={this.person_actived()}
                            access_group={current_access_group}
                            slave_access_group={current_slave_access_group}/>
          }

          {!current_access_group &&
            <Message error icon="ban"
                     header="É necessário configurar a ROTA DE ACESSO!"
                     content=""/>
          }
        </Tab.Pane>
    }

    const vehicle_access = {
      menuItem: 'Tags Veiculares',
      render: () =>
        <Tab.Pane key="tab3" as={'div'}>
          {current_access_group_merged_device_ids &&
            <VehicleAccessManager
              person={this.props.current}
              modules_activated={modules_activated}
              person_status={this.person_actived()}
              access_group={current_access_group_merged_device_ids}
              enable_ac_cloud_server={enable_ac_cloud_server}
            />}

          {!current_access_group_merged_device_ids &&
            <Message
              error icon="ban"
              header="É necessário configurar a ROTA DE ACESSO!"
              content=""
            />
          }
        </Tab.Pane>
    }

    const pwd = {
      menuItem: 'Cadastro de Senha',
      render: () =>
        <Tab.Pane key="tab7" as={'div'}>
          <PwdManager resident={this.props.current} person_status={this.person_actived()} access_group={current_access_group}
                      slave_access_group={current_slave_access_group}/>
        </Tab.Pane>
    }

    const neokoros = {
      menuItem: 'Controle de Acesso - Neokoros',
      render: () =>
        <Tab.Pane key="tab4" as={'div'}>
          <NeokorosManager resident={this.props.current} person_status={this.person_actived()}/>
        </Tab.Pane>
    }

    const guarita = {
      menuItem: 'Controle de Acesso - Guarita',
      // check_v2
      render: () => <Tab.Pane key="tab6" as={'div'}>
        <GuaritaManager resident={this.props.current} person_status={this.person_actived()}/>
      </Tab.Pane>
    }

    const panes = []

    const anviz_active = gv(this.state.settings, 'anviz__status') === '1'
    const controlid_active = gv(this.state.settings, 'controlid__status') === '1'
    const nk_active = gv(this.state.settings, 'nk__status') === '1'
    const zk_active = gv(this.state.settings, 'zk__status') === '1'
    const placa_ethernet_active = gv(this.state.settings, 'placa_ethernet__status') === '1'
    const hik_active = gv(this.state.settings, 'hikvision__fp_card') === '1'
    const dahua_active = gv(this.state.settings, 'dahua__fp_card') === '1'
    const bravas_active = gv(this.state.settings, 'bravas__status') === '1'
    const mip1000_active = gv(this.state.settings, 'mip1000__status') === '1'
    const controlid_tag_active = gv(this.state.settings, 'controlid__tag') === '1'

    const modules_activated = {
      anviz_active,
      controlid_active,
      nk_active,
      zk_active,
      placa_ethernet_active,
      hik_active,
      dahua_active,
      bravas_active,
      mip1000_active,
      controlid_tag_active,
    }

    const bio_car_perms = ['access_control.enroll_anviz', 'placa_ethernet__status', 'access_control.enroll_controlid']

    if ((anviz_active || placa_ethernet_active || nk_active || zk_active || controlid_active || hik_active || dahua_active) && hasPerm(bio_car_perms)) {
      panes.push(bio_card)
    }

    if ((gv(this.state.settings, 'nk__pwd') === '1') && hasPerm(['access_control.enroll_neokoros'])) {
      panes.push(pwd)
    }

    if (gv(this.state.settings, 'nk__status') === '1' && hasPerm(['access_control.enroll_neokoros'])) {
      panes.push(neokoros)
    }

    if (((enable_ac_cloud_server && has_guarita_ip) || gv(this.state.settings, 'guarita__status') === '1') && hasPerm(['access_control.enroll_guarita'])) {
      panes.push(guarita)
    }

    if (((enable_ac_cloud_server && has_vehicle_access) || bravas_active || mip1000_active || (controlid_active && controlid_tag_active)) && hasPerm(['access_control.enroll_guarita'])) {
      panes.push(vehicle_access)
    }

    let person = this.props.current
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={3}>
            {hv(person, 'photo.file') && <Image size={'small'} rounded src={person.photo.file}/>}

            {!hv(person, 'photo.file') &&
              <Image size={'small'} rounded src={require('../../../assets/images/default-profile.png')}/>}
          </Grid.Column>

          <Grid.Column width={12}>
            <Header as="h1" size={'huge'} style={{ paddingTop: 5 }}>
              {person.name}<br/>
            </Header>
            <Grid>
              <Grid.Column width={3}>
                <Header sub>Identificador:</Header>
                <span>{person.obj_number}</span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header sub>Id Equipamento:</Header>
                <span>{person.id_in_equipment}</span>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header sub>Rota de Acesso:</Header>
                <span>
                  <Dropdown options={this.state.access_groups} value={person.access_group_id} placeholder="Selecione"
                            onChange={this.handle_group_change}/>
                </span>
              </Grid.Column>

              {hasPerm(['access_control.handle_slave_access_group']) &&
                <Grid.Column width={4}>
                  <Header sub>Rota de Acesso Secundário:</Header>
                  <span>
                  <Dropdown options={this.state.access_groups} value={person.slave_access_group_id} placeholder="Selecione"
                            onChange={this.handle_slave_group_change}/>
                </span>
                </Grid.Column>}

            </Grid>

            <ServerServicePopup info_only={true}/>
          </Grid.Column>
        </Grid>

        <Divider/>

        {!this.person_actived() && <Message warning>
          <Message.Header>Essa PESSOA está desativada. Não será possível cadastrar dispositivos de acesso!</Message.Header>
        </Message>}

        <Tab
          menu={{ secondary: true, pointing: true, size: 'large' }}

          panes={[
            {
              menuItem: 'Detalhes', render: () =>
                <Tab.Pane key="tab0">

                  {hv(person, 'id') &&
                    <React.Fragment>
                      <h4 className="ui block header">Dados Pessoais</h4>
                      <Grid columns={'equal'}>
                        <Grid.Row>
                          <Grid.Column>
                            <Header sub>Nome:</Header>
                            <span>{person.name}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>CPF:</Header>
                            <span>{isPrivate('person__cpf') ? PrivacyData.maskPhone(person.cpf) : person.cpf}</span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Header sub>RG:</Header>
                            <span>{isPrivate('person__rg') ? PrivacyData.maskPhone(person.rg) : person.rg}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Data de Nascimento:</Header>
                            <span>{person.birthday && moment(person.birthday).format('DD/MM/YYYY')}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Sexo:</Header>
                            <span>{person.gender}</span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Header sub>Telefone:</Header>
                            <span>{isPrivate('person__phone') ? PrivacyData.maskPhone(person.phone) : person.phone}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Celular 1:</Header>
                            <span>{isPrivate('person__phone') ? PrivacyData.maskPhone(person.mobile1) : person.mobile1}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Celular 2:</Header>
                            <span>{isPrivate('person__phone') ? PrivacyData.maskPhone(person.mobile2) : person.mobile2}</span>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <div className="ui divider hidden"/>
                      <h4 className="ui block header">Pessoa para Contato </h4>
                      <Grid columns={'equal'}>
                        <Grid.Column>
                          <Header sub>Nome do Contato:</Header>
                          <span>{person.contact_name}</span>
                        </Grid.Column>
                        <Grid.Column>
                          <Header sub>Telefone do Contato:</Header>
                          <span>{person.contact_phone}</span>
                        </Grid.Column>
                        <Grid.Column>
                          <Header sub>Parentesco do Contato:</Header>
                          <span>{person.contact_kinship}</span>
                        </Grid.Column>
                      </Grid>


                      {hv(person, 'units') && person.units.length > 0 &&
                        <React.Fragment>
                          <div className="ui divider hidden"/>
                          <div className="ui divider hidden"/>
                          <h4 className="ui block header">Unidades</h4>

                          <Table basic={'very'}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Unidade</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Tipo</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                              {person.units.map(item => (
                                <Table.Row key={item.id}>
                                  <Table.Cell>{item.with_block}</Table.Cell>
                                  <Table.Cell>{this.formatStatus(item.pivot_status)}</Table.Cell>
                                  <Table.Cell>{this.formatType(item.pivot_type)}</Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </React.Fragment>}

                      <React.Fragment>
                        <div className="ui divider hidden"/>
                        <div className="ui divider hidden"/>
                        <h4 className="ui block header">Vagas</h4>

                        <Table basic={'very'}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Nome</Table.HeaderCell>
                              <Table.HeaderCell>Status</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {this.state.parkings.map(item => (
                              <Table.Row key={item.id}>
                                <Table.Cell>{item.with_pavement}</Table.Cell>
                                <Table.Cell>{item.status}</Table.Cell>
                                <Table.Cell>{item.type}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </React.Fragment>

                      <React.Fragment>
                        <div className="ui divider hidden"/>
                        <div className="ui divider hidden"/>
                        <h4 className="ui block header">Dependentes</h4>

                        <Table basic={'very'}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Nome</Table.HeaderCell>
                              <Table.HeaderCell>Data de Nascimento</Table.HeaderCell>
                              <Table.HeaderCell>Parentêsco</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>

                          <Table.Body>
                            {hv(this.state, 'person.dependents') && person.dependents.map(item => (
                              <Table.Row key={item.id}>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>{item.birthday && moment(item.birthday).format('DD/MM/YYYY')}</Table.Cell>
                                <Table.Cell>{item.kinship}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </React.Fragment>

                    </React.Fragment>}

                </Tab.Pane>
            },
            {
              menuItem: 'Cadastro de Face',
              render: () =>
                <Tab.Pane key="tab-face" as={'div'}>
                  <FaceEnroll
                    enable_sync_face={(enable_ac_cloud_server && has_facial) || enable_sync_face}
                    enable_ac_cloud_server={enable_ac_cloud_server}
                    person={this.props.current}
                    person_status={this.person_actived()}
                    access_group={current_access_group}
                    slave_access_group={current_slave_access_group}
                  />

                  {enable_sync_face && !current_access_group &&
                    <Message error icon="ban"
                             header="É necessário configurar a ROTA DE ACESSO!"
                             content=""/>
                  }
                </Tab.Pane>
            },
            ...panes
          ]}
        />

        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    data: state.people.data,
    current: state.people.current,
    showModal: state.people.showModal
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getObject: (id) => dispatch(person_actions.get(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleFormPage)
