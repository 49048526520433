import enviroment from '../enviroment'
import { apiAxios } from '../services/api'
import ToasterService from '../services/ToasterService'
import moment from 'moment/moment'

export const access_devices = {
  accessControlSettings: async () => {
    try {
      const { data } = await apiAxios.get(enviroment().apiUrl + 'admin/access-devices/access_control_settings')

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  accessDevicesFacials: async () => {
    try {
      const { data } = await apiAxios.get(enviroment().apiUrl + 'admin/access-devices' +
        '?filter{status}=1' +
        '&filter{model.in}=13' +
        '&filter{model.in}=17')

      return data.data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  accessDeviceEvents: async () => {
    try {
      const { data } = await apiAxios.get(enviroment().apiUrl + 'admin/access-devices/events' +
        `?filter{date.gte}=${moment().subtract(2, 'days').toISOString()}` +
        `&filter{date.lte}=${moment().add(1, 'days').toISOString()}&limit=200`)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  takePicture: async (device_id: string) => {
    try {
      const response = await apiAxios.get(enviroment().apiUrl + 'admin/access-devices/' + device_id + '/take_picture', { responseType: 'blob' })

      const blob = response.data
      // Verifica se o blob é realmente uma imagem
      if (!blob.type.startsWith('image/')) {
        // throw new Error('O conteúdo retornado não é uma imagem.')
      }

      return await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
      // return URL.createObjectURL(blob)

    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
}