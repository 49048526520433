import getMuiTheme from 'material-ui/styles/getMuiTheme'

const themeDefault = getMuiTheme({
  palette: {
    primaryColor: '#043F78',
  },
  appBar: {
    height: 57,
    color: '#032f58'
  },
  drawer: {
    width: 205,
    color: '#1a2b3c'
  },
  raisedButton: {
    primaryColor: '#043F78',
  },
  tabs: {
    backgroundColor: '#043F78',
  },
})

export default themeDefault