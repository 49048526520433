import * as React from 'react'
import { Component, createRef } from 'react'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import { uploadS3 } from '../services/api'
import { b64toBlob } from '../utils/device_utils'
import { show_modal } from '../utils/Formatters'
import ActivityLoading from './Loading'
import Webcam, { WebcamProps } from './Webcam'
import { useQuery } from 'react-query'
import { qs } from '../queries'
import ToasterService from '../services/ToasterService'

class FacialWebcam extends Component<any, any> {
  inputRef: React.RefObject<HTMLInputElement>
  intervalId: any

  constructor(props) {
    super(props)
    this.inputRef = createRef()
    this.state = {
      imgUrl: null,
      count: 0,
    }
    this.intervalId = null
  }

  componentDidMount() {
    this.intervalId = setInterval(async () => {
      try {
        if (this.state.count < 32) {
          const imgData = await qs.access_devices.takePicture(this.props.access_device_id)
          this.setState({ imgUrl: imgData, count: this.state.count + 1 })
        }
      } catch (error) {
        console.error('Erro ao buscar a imagem:', error)
      }
    }, 2000)
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  getScreenshot() {
    return this.state.imgUrl
  }

  render() {
    const { imgUrl } = this.state

    return (
      <div style={this.props.style}>
        {imgUrl && <img src={imgUrl || ''} alt="Imagem da câmera" style={this.props.style}/>}
      </div>
    )
  }
}

export const WebcamCapture = ({ refCapture, ...props }: WebcamProps & { refCapture: any, mirrored?: boolean }) => {
  const { data: faces } = useQuery('accessDevicesFacials', () => qs.access_devices.accessDevicesFacials(), {
    // six hours
    staleTime: 1000 * 60
  })

  const [deviceId, setDeviceId] = React.useState({})
  const [devices, setDevices] = React.useState<any[]>([])
  const [selectedFace, setSelectedFace] = React.useState<any>(null)

  const handleDevices = React.useCallback(
    mediaDevices =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  )

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices)
  }, [handleDevices])

  React.useEffect(() => {
    if (devices.length) {
      setDeviceId(devices[0].deviceId)
    }
  }, [devices])

  return (
    <div className={'webcam-capture-container'}>
      <Dropdown text="Selecione a Webcam" style={{ marginBottom: 12 }}>
        <Dropdown.Menu>
          {devices.map((device, key) => (
            // @ts-ignore
            <Dropdown.Item key={device.label} text={device.label} onClick={() => {
              setSelectedFace(null)
              setDeviceId(device.deviceId)
            }}/>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

      <Dropdown text="Selecione o Facial" style={{ marginBottom: 12 }}>
        <Dropdown.Menu>
          <Dropdown.Item text={'-- Vazio --'} onClick={() => setSelectedFace(null)}/>
          {Array.isArray(faces) && faces.map((face, key) => (
            <Dropdown.Item text={face.name} key={face.id} onClick={() => {
              setSelectedFace(face)
              setDeviceId(null)
            }}/>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div className={'webcam-box-container'}>
        {!selectedFace?.id &&
          // @ts-ignore */
          <Webcam mirrored={false} {...props} videoConstraints={{ ...props.videoConstraints, deviceId: deviceId }} ref={refCapture}/>}

        {selectedFace?.id &&
          // @ts-ignore */
          <FacialWebcam
            ref={refCapture}
            access_device_id={selectedFace.id}
            style={{ width: 'auto', maxWidth: props.minScreenshotHeight, maxHeight: props.minScreenshotHeight }}
          />}
      </div>
    </div>
  )
}

type UploaderProps = {
  file_type?: string
  values: any,
  setFieldValue: any,
  size: 'large' | 'medium' | 'small',
  show_success_modal?: boolean,
  hide_save_btn?: boolean,
  postAction?: any,
  isProfile?: boolean
}

export class WebcamCaptureUploader extends React.Component<UploaderProps, any> {
  static defaultProps = {
    file_type: 'attachments',
    size: 'large',
    show_success_modal: true
  }

  state = {
    loading: false,
    base64: ''
  }

  webcam_ref: any

  takePicture = () => {
    const imageSrc = this.webcam_ref.getScreenshot()
    this.setState({ base64: imageSrc })
  }

  savePhoto = async () => {
    if (this.state.base64 !== '') {
      this.setState({ loading: true })

      let _base64 = this.state.base64.split(',')[1]
      const blob = b64toBlob(_base64, 'image/jpeg')

      return uploadS3(blob, this.props.file_type, 'gater_ui_file.jpg').then((res) => {
        this.setState({ loading: false })

        const tempFile = this.props.values.files_attach

        if (Array.isArray(tempFile)) {
          tempFile.push(res.id)
          this.props.setFieldValue('files_attach', tempFile)
        }

        this.props.setFieldValue('file', res.id)

        if (this.props.postAction) {
          this.props.postAction(tempFile)
        }

        if (this.props.show_success_modal) {
          show_modal('Foto salva, clique em "Enviar" para concluir!', 'success')
        }

        return {
          files_attach: tempFile,
          file_id: res.id
        }

      }).catch((err) => {
        console.log(err)
        this.setState({ loading: false })
        show_modal('Erro ao salvar a foto!', 'error')
        return Promise.reject('Error')
      })
    } else {
      show_modal('Capture uma foto primeiro', 'error')
      return Promise.reject('Error')
    }
  }

  render() {
    const sizes = {
      'large': { width: 480 },
      'medium': { width: 380 },
      'small': { width: 200 }
    }

    return (
      <React.Fragment>
        <Button primary size={'large'} onClick={this.takePicture} type="button">
          <Icon name="camera"/> 1 - Capturar Foto
        </Button>
        {!this.props.hide_save_btn &&
          <Button primary size={'large'} onClick={this.savePhoto} type="button">
            <Icon name="save"/>2 - Salvar Foto
          </Button>}

        <Button as="label" htmlFor="file" type="button" primary size={'large'} basic>
          Escolher Foto (computador)
        </Button>
        <input accept=".jpg,.jpeg,.png" type="file" id="file" style={{ display: 'none' }} onChange={(e) => {
          this.setState({ loading: true })

          uploadS3(e.target.files[0], this.props.file_type, 'gater_ui_file.jpg').then((res) => {
            this.setState({ loading: false })

            const tempFile = this.props.values.files_attach

            if (Array.isArray(tempFile)) {
              tempFile.push(res.id)
              this.props.setFieldValue('files_attach', tempFile)
            }

            this.props.setFieldValue('file', res.id)

            ToasterService.success('Foto salva, clique em "Enviar" para concluir!')

          }).catch((err) => {
            console.log(err)
            this.setState({ loading: false })
            show_modal('Erro ao salvar a foto!', 'error')
            return Promise.reject('Error')
          })

        }}/>


        <div className="capture" style={styles.capture}>
          <div style={styles.box}>
            <WebcamCapture
              audio={false}
              refCapture={(ref) => this.webcam_ref = ref}
              screenshotFormat="image/jpeg"

              videoConstraints={{
                height: 720,
                facingMode: 'user'
              }}

              screenshotQuality={0.8}
              minScreenshotHeight={sizes[this.props.size].width}
              style={sizes[this.props.size]}

              isProfile={this.props.isProfile}
              mirrored={false}
              forceScreenshotSourceSize
              imageSmoothing
              onUserMedia={stream => {}}
              onUserMediaError={error => {}}
            />
          </div>

          <div style={styles.boxCaptured}>
            <img src={this.state.base64} style={{ width: 'auto', maxWidth: sizes[this.props.size].width, maxHeight: sizes[this.props.size].width }}/>
          </div>
        </div>

        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

const styles = {
  box: {
    paddingRight: 25
  },
  boxCaptured: {
    marginTop: 30
  },
  capture: {
    padding: 15,
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: '#ebebeb',
    display: 'flex'
  }
}
