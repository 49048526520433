import React, { useEffect } from 'react'
import { useAccessDeviceSettingsQuery, useLocalServerSettingsQuery, useMeQuery } from '../hooks'
import { Button, Label, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { gv } from '../utils/common'
import ServerServiceController from './ServerServiceController'
import { history } from '../redux/store/configureStore'
import ServerServiceManager from '../services/ServerServiceManager'
import { actionMap as system_actions } from '../redux/modules/system'

const style = {
  tags: {
    marginBottom: 15,
  },
}

const ServerServicePopup = (props: { info_only?: boolean }) => {
  const { data: me } = useMeQuery()
  const { data: settings } = useLocalServerSettingsQuery()
  const { data: access_control_settings } = useAccessDeviceSettingsQuery()

  const dispatch = useDispatch()
  const status_server = useSelector((state: any) => state.system.status_server)
  const db_server_running = useSelector((state: any) => state.system.db_server_running)
  const server_installed = useSelector((state: any) => state.system.server_installed)

  useEffect(() => {
    const inter2 = setInterval(async () => {
      if (typeof CefSharp !== 'undefined') {
        let { postgres_status, mongo_status } = await ServerServiceManager.getDBServiceStatus()
        let is_server_installed = await ServerServiceManager.isServerInstalled()
        dispatch(system_actions.db_server_running(postgres_status && mongo_status))
        dispatch(system_actions.server_installed(is_server_installed))
      }
    }, 1000 * 10)

    return () => {
      clearInterval(inter2)
    }

  }, [])

  return (
    <Popup flowing hoverable on="click" position="bottom center" verticalOffset={18}
           style={{ overflowY: 'scroll', maxHeight: '450px' }}
           trigger={
             <Label
               size="large" style={style.tags}
               color={
                 access_control_settings?.enable_ac_cloud_server ? 'blue'
                   : server_installed
                     ?
                     db_server_running ? status_server ? 'blue' : 'red' : 'yellow'
                     :
                     status_server ? 'blue' : 'red'
               }
             >
               SERVIDOR&nbsp;
               {
                 access_control_settings?.enable_ac_cloud_server ? 'Conectado'
                   : server_installed
                     ?
                     (status_server && db_server_running) ? 'Conectado' : 'Desconectado'
                     :
                     status_server ? 'Conectado' : 'Desconectado'
               }
             </Label>
           }>

      <React.Fragment>
        <p><b>Ip do Servidor: </b> {gv(me, 'team.configs.ac_local_server_ip')}</p>
        <p><b>Versão do Servidor: </b> {gv(settings, '_server_version')}</p>
        <p><b>Código Condomínio: </b> {gv(me, 'team.schema_name')}</p>

        <ServerServiceController show_install={false} info_only={props.info_only}/>

        <br/>
        <br/>

        <Button
          compact size="mini" color="grey" basic type="button"
          onClick={() => history.push('/admin/panel-hdw')}
          style={{ opacity: 0.00 }}
        />
      </React.Fragment>
    </Popup>

  )
}

export default ServerServicePopup

